<script setup lang="ts">
const nuxtApp = useNuxtApp()
const route = useRoute()
const { activeHeadings, updateHeadings } = useScrollspy()
const { handleRedirect } = useAppRedirect()
const colorMode = useColorMode()

const isHomePage = computed(() => route.path === '/' || route.path === '')

const links = computed(() => [{
  label: 'Le défi',
  to: '/#problem',
  icon: 'i-heroicons-exclamation-triangle',
  active: isHomePage.value && activeHeadings.value.includes('problem') && !activeHeadings.value.includes('features')
}, {
  label: 'Fonctionnalités',
  to: '/#features',
  icon: 'i-heroicons-cube-transparent',
  active: isHomePage.value && activeHeadings.value.includes('features') && !activeHeadings.value.includes('pricing')
}, {
  label: 'Tarifs',
  to: '/#pricing',
  icon: 'i-heroicons-credit-card',
  active: isHomePage.value && activeHeadings.value.includes('pricing') && !activeHeadings.value.includes('testimonials')
}, {
  label: 'FAQ',
  to: '/#faq',
  icon: 'i-heroicons-question-mark-circle',
  active: isHomePage.value && activeHeadings.value.includes('faq')
}])

const buttonConfig = computed(() => ({
  label: isHomePage.value ? "Accéder à l'application" : "En savoir plus",
  action: isHomePage.value ? handleRedirect : () => navigateTo('/')
}))

const updatePageHeadings = () => {
  if (isHomePage.value) {
    nextTick(() => {
      updateHeadings([
        document.querySelector('#problem'),
        document.querySelector('#features'),
        document.querySelector('#pricing'),
        document.querySelector('#faq')
      ])
    })
  } else {
    updateHeadings([])
  }
}

// Mettre à jour les headings au montage du composant
onMounted(updatePageHeadings)

// Mettre à jour les headings à chaque changement de page
nuxtApp.hooks.hook('page:finish', updatePageHeadings)

// Nettoyer le hook lors de la destruction du composant
onUnmounted(() => {
  nuxtApp.hooks.hook('page:finish', updatePageHeadings)
})
</script>

<template>
  <UHeader :links="isHomePage ? links : []">
    <template #logo>
      <img src="/images/logo-mamut.svg" alt="MaMut Logo" class="h-8 w-auto dark:invert" />
      MaMut
    </template>

    <template #right>
      <UButton :label="buttonConfig.label" color="primary" class="hidden lg:flex" @click="buttonConfig.action" />
    </template>

    <template #panel>
      <UAsideLinks v-if="isHomePage" :links="links" />

      <UDivider v-if="isHomePage" class="my-6" />

      <UButton :label="buttonConfig.label" color="primary" block class="mb-3" @click="buttonConfig.action" />
    </template>
  </UHeader>
</template>
