export const useAppRedirect = () => {
    const showModal = useState<boolean>('notifyModal', () => false)
    const runtimeConfig = useRuntimeConfig()
    const appAvailable = runtimeConfig.public.appAvailable
    const appSubdomain = runtimeConfig.public.appSubdomain

    const handleRedirect = () => {
        if (!appAvailable) {
            showModal.value = true
        } else {
            window.location.href = `https://${appSubdomain}`
        }
    }

    return {
        showModal,
        handleRedirect
    }
}
