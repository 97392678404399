<script setup lang="ts">
useHead({
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'robots', content: 'index, follow' },
    { name: 'googlebot', content: 'index, follow' },
    { name: 'format-detection', content: 'telephone=no' },
    { name: 'author', content: 'MaMut' }
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' },
    { rel: 'canonical', href: 'https://ma-mut.fr' },
    { rel: 'alternate', hreflang: 'fr-FR', href: 'https://ma-mut.fr' }
  ],
  htmlAttrs: {
    lang: 'fr',
    dir: 'ltr'
  }
})

useSeoMeta({
  title: 'MaMut - Simplifiez vos mutations | Outil pour professeurs',
  description: 'Trouvez rapidement les établissements dans votre nouvelle académie avec MaMut, l\'outil tout-en-un pour les professeurs. Mutations simplifiées, recherche d\'établissements et calcul de trajets.',

  ogTitle: 'MaMut - Simplifiez vos mutations | Outil pour professeurs',
  ogDescription: 'Trouvez rapidement les établissements dans votre nouvelle académie avec MaMut, l\'outil tout-en-un pour les professeurs.',
  ogImage: '/images/social-card.png',
  ogType: 'website',
  ogSiteName: 'MaMut',
  ogUrl: 'https://ma-mut.fr',

  twitterTitle: 'MaMut - Simplifiez vos mutations | Outil pour professeurs',
  twitterDescription: 'Trouvez rapidement les établissements dans votre nouvelle académie avec MaMut.',
  twitterImage: '/images/social-card.png',
  twitterCard: 'summary_large_image',
  twitterCreator: '@votre_compte',
  twitterSite: '@votre_compte',

  themeColor: '#4F46E5'
})
</script>

<template>
  <AppHeader />

  <UMain>
    <NuxtPage />
  </UMain>

  <AppFooter />

  <UNotifications />
</template>
