<script setup lang="ts">
const links = [{
  label: 'Resources',
  children: [{
    label: 'Help center'
  }, {
    label: 'Docs'
  }, {
    label: 'Roadmap'
  }, {
    label: 'Changelog'
  }]
}, {
  label: 'Features',
  children: [{
    label: 'Affiliates'
  }, {
    label: 'Portal'
  }, {
    label: 'Jobs'
  }, {
    label: 'Sponsors'
  }]
}, {
  label: 'Company',
  children: [{
    label: 'About'
  }, {
    label: 'Pricing'
  }, {
    label: 'Careers'
  }, {
    label: 'Blog'
  }]
}]

const toast = useToast()

const email = ref('')
const loading = ref(false)

async function onSubmit() {
  loading.value = true

  try {
    await $fetch('/api/notify', {
      method: 'POST',
      body: { email: email.value }
    })

    toast.add({
      title: 'Merci !',
      description: 'Vous avez été inscrit à notre newsletter avec succès.',
      color: 'green'
    })

    email.value = '' // Réinitialisation du champ email
  } catch (error) {
    toast.add({
      title: 'Erreur',
      description: 'Une erreur est survenue lors de l\'inscription.',
      color: 'red'
    })
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <UFooter>
    <template #top>
      <form @submit.prevent="onSubmit">
        <UFormGroup label="Inscrivez-vous à notre newsletter" :ui="{ container: 'mt-3' }">
          <UInput v-model="email" type="email" placeholder="Entrez votre email"
            :ui="{ icon: { trailing: { pointer: '' } } }" required size="xl" autocomplete="off" class="max-w-sm">
            <template #trailing>
              <UButton type="submit" size="xs" :label="loading ? 'Inscription...' : 'S\'inscrire'" :loading="loading" />
            </template>
          </UInput>
        </UFormGroup>
      </form>
      <!-- <UFooterColumns :links="links">
        <template #right>

        </template>
</UFooterColumns> -->
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        Copyright © {{ new Date().getFullYear() }}. Tous droits réservés.
      </p>
    </template>

    <template #right>
      <UColorModeButton size="sm" />

    </template>
  </UFooter>
</template>
